export const CORE_URL = (process.env.NEXT_PUBLIC_ODDULAR_CORE_URL || '').trim();
export const FILE_URL = (
  process.env.NEXT_PUBLIC_ODDULAR_FILE_DOWNLOAD_PREPEND || ''
).trim();

export const SITE_URL = 'https://files.oddular.com';
export const SITE_ORIGIN =
  process.env.NEXT_PUBLIC_SITE_ORIGIN || new URL(SITE_URL).origin;
export const APP_VERSION = '0.0.1';
export const TWITTER_USER_NAME = 'oddularhq';
export const BRAND_NAME = 'Oddular';
export const SITE_NAME = 'Oddular';
export const META_DESCRIPTION = 'Automate, Build, and Download Reports';
export const SITE_DESCRIPTION = 'Automate, Build, and Download Reports.';
export const TWEET_TEXT = META_DESCRIPTION;

// Remove process.env.NEXT_PUBLIC_... below and replace them with
// strings containing your own privacy policy URL and copyright holder name
export const LEGAL_URL = process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL;
export const COPYRIGHT_HOLDER = process.env.NEXT_PUBLIC_COPYRIGHT_HOLDER;

export const NAVIGATION = [
  { name: 'All Files', route: '/all',  },
];

export const TERMS_OF_SERVICE =
  'https://www.oddular.com/support/policies-and-safety/terms-of-service';
export const PRIVACY_POLICY =
  'https://www.oddular.com/support/policies-and-safety/privacy';
export const LICENSE_AGREEMENT = '';

export const REPORT_JOB_STATUSES = {
  READY: 'Ready',
  IN_PROGRESS: 'Generating Report',
  QUEUE: 'Waiting in Queue',
  ERROR: 'Error',
};